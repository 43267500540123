import Typo from 'src/components/typo/typo';
import api from 'src/api/api';
import { getClientIp, setResponseHeaderIp } from 'src/lib/utils';
import ImageCard from 'src/components/card/image-card';
import LogoWhite from 'public/icon/LogoWhite.svg';
import Button from 'src/components/button/button';
import { useRouter } from 'next/router';
import useFirebaseAuth from 'src/lib/ap-module/react/use-firebase-auth';
import groupService from 'src/lib/ap-module/service/firebase/group-service';
import ASGroup from 'src/lib/ap-module/activity-stream/group/group';

export async function getServerSideProps(context) {
  try {
    const clientIp = getClientIp(context.req);
    api._setting.setIpHeader(clientIp);
    setResponseHeaderIp(context);

    /* 기본값으로 가져오기; */
    const groupRes = await groupService.getGroupList();

    return { props: { group: groupRes } };
  } catch (e) {
    console.log(e);
    return { props: { group: [] } };
  }
}

interface Props {
  group: ASGroup[];
}

export default function Index({ group }: Props) {
  const { user, logout } = useFirebaseAuth();
  const router = useRouter();

  return (
    <>
      <section className='relative w-full hero-section'>
        <div className='md:px-12 sm:px-5 bg-ui-primary md:rounded-b-[50px] sm:rounded-b-[40px] pt-[7%] md:pb-[4%] sm:pb-[16%]'>
          <div className='grid md:grid-cols-2 sm:grid-cols-1'>
            <div className='py-1'>
              <img src={LogoWhite.src} alt='logo' className='md:w-[198px] sm:w-[135px]'></img>
            </div>
            {user ? (
              <div className='flex items-center justify-between py-1'>
                <Typo.Body
                  bold='light'
                  size='b2'
                  element='p'
                  className='inline-block mr-1 align-middle text-typo-white'
                >
                  {user.email}
                </Typo.Body>

                <Button
                  color='linePrimary'
                  onClick={() => {
                    router.push('/account/mypage');
                  }}
                  className='inline-block mr-2 align-middle'
                >
                  내 정보
                </Button>

                <Button
                  color='linePrimary'
                  onClick={() => logout()}
                  className='inline-block align-middle'
                >
                  로그아웃
                </Button>
              </div>
            ) : (
              <div className='flex items-center justify-end'>
                <Button
                  className='mr-2'
                  color='linePrimary'
                  onClick={() => {
                    router.push('/account/login');
                  }}
                >
                  로그인
                </Button>

                <Button
                  color='linePrimary'
                  onClick={() => {
                    router.push('/account/join');
                  }}
                >
                  회원가입
                </Button>
              </div>
            )}
          </div>

          <hr className='my-[4%]'></hr>

          <Typo.Heading size='h3' bold='medium' element='h3' className='text-typo-white mb-[2%]'>
            나의 아이디어, <br></br>우리 동네에 스며들다!
          </Typo.Heading>

          <Typo.Body size='b2' bold='light' element='p' className='text-typo-white'>
            지역 별 커뮤니티에서 새로운 정보를 나누고<br></br>
            청원, 투표, 참여 등 지역에 아이디어를 공유하세요
          </Typo.Body>
        </div>
      </section>

      <section className='py-4 md:px-12 sm:px-5'>
        <div className='mb-2'>
          <Typo.Heading size='h3' bold='bold' element='h3' className='text-typo-gray-900'>
            Where is my Localink?
          </Typo.Heading>
          <Typo.Body size='b2' bold='medium' element='p' className='text-typo-gray-500'>
            내 지역에 개설된 로컬링크 채널에서 <br /> 이웃과 함께 정보를 나누세요
          </Typo.Body>
        </div>

        <div className='grid grid-cols-2 gap-4'>
          {group.map((elem) => {
            return (
              <div key={elem.id}>
                <ImageCard
                  title={elem.name}
                  description={elem.summary}
                  imageUrl={elem.image}
                  link={`${elem.id}`}
                />
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}
