import React, { useEffect, useState } from 'react';
import Typo from '../typo/typo';
import Logo from 'public/icon/Logo.svg';
import { ContentCardProps } from './content-card';
import Link from 'next/link';

const ImageCard = ({
  title,
  description,
  imageUrl,
  isFirebaseImage,
  link,
  topBadge,
  bottomBadge,
}: ContentCardProps) => {
  const Result = () => (
    <div className='relative sm:w-[137px] md:w-[300px] sm:h-[96px] md:h-[199px] select-none'>
      {topBadge && (
        <div className='absolute z-10 flex flex-row justify-start top-4 left-4'>{topBadge}</div>
      )}

      <img
        className='absolute inset-0 object-cover object-center w-full h-full rounded-xl'
        src={imageUrl}
        alt={title}
      />

      <div className='w-full h-full bg-black opacity-50 rounded-xl'></div>

      <div className='absolute sm:inset-x-2 sm:inset-y-6 md:inset-x-5 md:inset-y-8'>
        <Typo.Heading
          size='h3'
          bold='semibold'
          element='h3'
          className='opacity-100 text-typo-white'
        >
          {title}
        </Typo.Heading>

        <Typo.Body size='b2' bold='light' element='p' className='pt-2 opacity-100 text-typo-white'>
          {description}
        </Typo.Body>
      </div>

      {bottomBadge && (
        <div className='absolute z-10 flex flex-row justify-start bottom-4 left-4'>
          {bottomBadge}
        </div>
      )}
    </div>
  );

  if (link) {
    return (
      <Link href={link}>
        <Result />
      </Link>
    );
  } else return <Result />;
};

export default ImageCard;
